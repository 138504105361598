<template>
  <div>
    <b-form-input
      size="lg"
      class="my-3"
      v-model="filter"
      placeholder="Search"
      ref="search"
      id="search"
      autofocus
    />
    <b-card-group columns>
      <b-card v-for="(item, key) in itemsFiltered" :key="key">
        <b-card-title class="mb-0">
          <b-link
            v-if="typeof item === 'string' && item.startsWith('[data-ui]')"
            :to="`/data-ui/${item.replace('[data-ui]', '')}`"
          >
            {{ key }}
            <sup>🟊</sup>
          </b-link>
          <b-link v-else-if="typeof item === 'string'" :to="`/data/${item}`">
            {{ key }}
          </b-link>
          <template v-else>{{ key }}</template>
        </b-card-title>
        <ul v-if="typeof item === 'object'">
          <li v-for="(link, name) in item" :key="name">
            <b-link
              v-if="link.startsWith('[external]')"
              :to="`${link.replace('[external]', '')}`"
            >
              {{ name }}
            </b-link>
            <b-link
              v-else-if="link.startsWith('[data-ui]')"
              :to="`/data-ui/${link.replace('[data-ui]', '')}`"
            >
              {{ name }}
              <sup>🟊</sup>
            </b-link>
            <b-link v-else :to="`/data/${link}`">{{ name }}</b-link>
          </li>
        </ul>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Entities from '../../entities';
import { DataUiBlueprints } from '@gid/models';
import { getConnection } from 'typeorm';

export default {
  data() {
    return {
      itemsDefinition: {
        Opportunity: {
          Opportunities: '[data-ui]Opportunity',
          'Opportunity Options': '[data-ui]OpportunityOption',
          'Partner Briefing Documents': '[data-ui]PartnerBriefingDocument',
          'Job Automation': '[data-ui]JobAutomation',
          'Opportunity Tags': '[data-ui]OpportunityTags',
        },
        Products: {
          Products: '[data-ui]Product',
          'Product Types': '[data-ui]ProductTypeEnum',
          'Product Bundles': '[data-ui]ProductBundle',
          'Product Services': '[data-ui]ProductService',
          'Product Opportunities': '[data-ui]ProductOpportunity',
          OEMs: '[data-ui]Oem',
          Measures: '[data-ui]Measure',
        },
        Services: {
          'Normalized Services': '[data-ui]Service',
          'Opportunity Services': '[data-ui]OpportunityService',
          Services: '[data-ui]ServiceView',
          'Service Sortings': '[data-ui]ServiceSortings',
          'Service Links': '[data-ui]ServiceLink',
          'Custom Services': '[data-ui]CustomService',
        },
        Rejections: {
          Brands: '[data-ui]RejectedBrand',
          Opportunties: '[data-ui]RejectedOpportunity',
          Jobs: '[data-ui]RejectedJob',
          Services: '[data-ui]RejectedService',
        },
        Cancellations: {
          'Cancellation Reason': '[data-ui]CancellationReason',
          'Cancellation Reason Languages': '[data-ui]CancellationReasonLang',
          'Cancellation Reason Opportunities':
            '[data-ui]CancellationReasonOpportunity',
        },
        Accounts: {
          Account: '[data-ui]Account',
          Brand: '[data-ui]Brand',
          Partner: '[data-ui]Partner',
          Delegations: '[data-ui]Delegations',
          Customer: '[data-ui]Customer',
          Contacts: '[data-ui]Contact',
          'no DeDup Emails': '[data-ui]NoDedupEmails',
          'Account Profiles': '[data-ui]AccountProfile',
          'Contact Profiles': '[data-ui]ContactProfile',
        },
        Partner: {
          'Partner Opportunity Radius': '[data-ui]PartnerOpportunityRadius',
          'Partner Company Documents': '[data-ui]PartnerCompanyDocs',
        },
        'Affiliate Program': {
          'Affiliate Ids': '[data-ui]AffiliateId',
          'Affiliate Id Services': '[data-ui]AffiliateIdService',
          'Affiliate Id Service Selector':
            '[data-ui]AffiliateIdServiceSelector',
        },
        'Order Intake Forms': {
          Identification: 'order-intake-forms-identification',
          Urls: 'order-intake-forms-urls',
          Answers: '[data-ui]Answer',
          'Flag Options': '[data-ui]FlagOption',
          'Questions 2 Options': '[data-ui]QuestionOption',
        },
        Jobs: {
          Job: '[data-ui]Job',
          'Job Service': '[data-ui]JobService',
          'Job Product': '[data-ui]JobProduct',
          'Job Media File': '[data-ui]JobMediaFile',
          'Job Additional Item': '[data-ui]JobAdditionalItem',
        },
        Email: '[data-ui]Email',
        Accounting: {
          'Accounting Document': '[data-ui]AccountingDocument',
          'Accounting Document Item': '[data-ui]AccountingDocumentLineItem',
        },
        Picklist: '[data-ui]Picklist',
        'Job Imports': 'job-import',
        'Job Status Description': '[data-ui]JobStatusDescription',
        Payments: '[data-ui]PaymentSession',
        Admin: {
          'User Simulation Log': '[data-ui]UserSimulationLog',
        },
        'Currency & Tax Configurations': '[data-ui]CurrencyTaxConfiguration',
        Templates: 'templates',
        Images: '[data-ui]Image',
        Brands: {
          'Brand Assets': 'brand-assets',
          Webhooks: '[data-ui]BrandWebhookConfiguration',
        },
        Carriers: 'carrier',
      },
      filter: null,
    };
  },
  computed: {
    ...mapGetters(['environment']),
    contactProfiles() {
      return this.$store.state.auth.user?.contact?.profiles;
    },
    items() {
      return this.onlyAccessibleItems(this.itemsDefinition);
    },
    itemsFiltered() {
      if (this.filter) {
        const items = {};
        const search = new RegExp(this.filter, 'i');
        for (const key in this.items) {
          const item = this.items[key];
          if (search.test(key)) {
            items[key] = item;
          } else if (search.test(JSON.stringify(item))) {
            items[key] = {};
            for (const subKey in item) {
              const subItem = item[subKey];
              if (search.test(subKey) || search.test(subItem)) {
                items[key][subKey] = subItem;
              }
            }
          }
        }
        return items;
      } else {
        return this.items;
      }
    },
  },
  mounted() {
    this.$refs.search?.focus();
  },
  methods: {
    onlyAccessibleItems(items) {
      const checkEntity = (entityName) => {
        const connection = getConnection();
        let permissions = Entities[entityName]?.permissions;
        if (!permissions && entityName.startsWith('[data-ui]')) {
          // Try to load permissions from decorators
          const entityDescription = DataUiBlueprints.get(
            entityName.replace('[data-ui]', ''),
          );
          permissions = entityDescription?.permissions?.read;
        }
        if (!permissions) {
          return false;
        }
        return (
          this.contactProfiles &&
          permissions.some((p) => this.contactProfiles.includes(p))
        );
      };

      const filtered = {};
      for (const [name, entityDefinition] of Object.entries(items)) {
        if (
          typeof entityDefinition === 'string' &&
          checkEntity(entityDefinition)
        ) {
          filtered[name] = entityDefinition;
        }
        if (typeof entityDefinition === 'object') {
          const result = this.onlyAccessibleItems(entityDefinition);
          if (Object.keys(result).length > 0) {
            filtered[name] = result;
          }
        }
      }
      return filtered;
    },
  },
};
</script>
